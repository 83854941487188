import React from "react"
import styled from "styled-components"
import { PrimaryBtn } from "../elements"
import {
  colorWhite,
  CellBoxShadow,
  colorPrimary,
  colorSuccess,
} from "../utilities/variables"
import { Link, navigate } from "gatsby"
import useWindowSize from "../utilities/hooks/useWindowSize"
import { Burger, Menu } from "./hamburguer-menu"
import { useStaticQuery, graphql } from "gatsby"

export const StyledMenuItem = styled(Link)`
  font-size: 16px;
  line-height: 16px;
  color: ${colorPrimary};
  cursor: pointer;
  text-decoration: none;
  padding: 0 10px 3px 10px;
  -webkit-font-smoothing: antialiased;
  :active {
    font-weight: bold;
    @media (min-width: 1050px) {
      padding-bottom: 0;
      border-bottom: 3px ${colorSuccess} solid;
    }
  }
`

const Header = styled.header`
  background: ${colorWhite};
  box-shadow: ${CellBoxShadow};
  position: sticky;
  top: 0;
  width: 100%;
  height: 90px;
  z-index: 3;
  > div {
    height: 100%;
    align-items: center;
    margin: 0 auto;
    gap: 100px;
    display: grid;
    max-width: 1300px;
    padding: 0 5%;
    grid-template-columns: max-content 1fr;
    @media (min-width: 1050px) {
      grid-template-columns: max-content 1fr max-content;
    }
  }
  .menu-items {
    display: grid;
    grid-template-columns: ${props => `repeat(${props.count}, max-content)`};
    justify-content: space-between;
    justify-self: end;
    text-align: end;
    max-width: 800px;
    width: 100%;
    @media (min-width: 1200px) {
      padding-left: 50px;
      /* padding: 0; */
    }
  }
  .get-started {
    width: 122px;
  }
`

export const StyledHeader = () => {
  const size = useWindowSize()
  const [open, setOpen] = React.useState(false)

  const data = useStaticQuery(graphql`
    query {
      allStrapiNavbar {
        edges {
          node {
            id
            Logo {
              publicURL
            }
            Button {
              Content
              Link
              id
            }
            Navigation {
              Item {
                Link
                Title
                id
              }
              id
            }
          }
        }
      }
      allStrapiFooter {
        edges {
          node {
            id
            AppStoreLink
            GooglePlayLink
          }
        }
      }
    }
  `)

  const content = data.allStrapiNavbar.edges[0].node
  const itemCount = Object.keys(content.Navigation.Item).length

  const handleClick = url => {
    navigate(url)
  }

  return (
    <Header count={itemCount}>
      <div>
        <Link to="/">
          <img src={content.Logo.publicURL} alt="logo" />
        </Link>
        {size.width >= 1050 ? (
          <>
            <div className="menu-items">
              {content.Navigation.Item.map(item => {
                return (
                  <StyledMenuItem key={item.id} to={item.Link}>
                    {item.Title}
                  </StyledMenuItem>
                )
              })}
            </div>
            <PrimaryBtn
              className="get-started"
              onClick={() => handleClick(content.Button.Link)}
            >
              {content.Button.Content}
            </PrimaryBtn>
          </>
        ) : (
          <>
            <Burger open={open} setOpen={setOpen} />
            <Menu
              items={content.Navigation.Item}
              open={open}
              setOpen={setOpen}
              button={content.Button}
            />
          </>
        )}
      </div>
    </Header>
  )
}
