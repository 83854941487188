import React, { useState, useEffect, createRef } from "react"
import SEO from "../components/seo"
import {
  LgContentText,
  HeaderLargeText,
  InputLabel,
  Input,
  HeaderText,
  InputContainer,
  TextArea,
  PrimaryBtn,
  SubHeaderText,
} from "../elements"
import animation from "../assets/animations/contact-us/toot-web-contact-us.json"
import lottie from "lottie-web"
import Layout from "../components/layout"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

const StyledForm = styled.form`
  display: grid;
  max-width: 480px;
  text-align: start;
  margin: 0 auto;
  gap: 38px;
  padding: 0 15px;
  button {
    margin: auto;
  }
  .split {
    display: grid;
    gap: 38px 18px;
    grid-template-columns: 1fr;
    @media (min-width: 900px) {
      grid-template-columns: 1fr 1fr;
    }
  }
`

const StyledColumns = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  text-align: center;
  max-width: 740px;
  padding: 0 15px;
  margin: auto;
  @media (min-width: 750px) {
    /* grid-template-columns: 1fr 1fr 1fr; */
    text-align: start;
    gap: 0;
  }
  h3 {
    display: grid;
    gap: 20px;
    font-size: 20px;
  }
`

const StyledLgContentText = styled(LgContentText)`
  margin: 20px auto;
  text-align: center;
`

const ContactUs = () => {
  const [formDetails, setFormDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    subject: "",
    text: "",
  })
  const [contactState, setContactState] = useState()
  let animationContainer = createRef()

  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: animation,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const data = useStaticQuery(graphql`
    query {
      allStrapiContactUs {
        edges {
          node {
            Description
            Email
            SpanishSupport
            Title
            UKSupport
            id
          }
        }
      }
    }
  `)

  const content = data.allStrapiContactUs.edges[0].node

  const handleSubmit = e => {
    e.preventDefault()
    fetch(`https://us-central1-toot-1ee81.cloudfunctions.net/contactUs`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: formDetails.email,
        subject: formDetails.subject,
        message: formDetails.text,
      }),
    })
      .then(response =>
        response.status === 200 ? setContactState(true) : setContactState(false)
      )
      .catch(error => {
        console.error(error)
      })
  }

  return (
    <Layout>
      <SEO title="Contact us" />
      <HeaderLargeText>{content.Title}</HeaderLargeText>
      <StyledLgContentText
        dangerouslySetInnerHTML={{ __html: content.Description }}
      ></StyledLgContentText>
      <StyledColumns>
        <SubHeaderText>
          <span>Email</span>
          <a href={`mailto:` + content.Email} target="_blank" rel="noreferrer">
            {content.Email}
          </a>
        </SubHeaderText>
        {/* <SubHeaderText>
          <span>UK Support</span>
          <a href={`tel:` + content.UKSupport} target="_blank" rel="noreferrer">
            {content.UKSupport}
          </a>
        </SubHeaderText>
        <SubHeaderText>
          <span>Spanish Support</span>
          <a
            href={`tel:` + content.SpanishSupport}
            target="_blank"
            rel="noreferrer"
          >
            {content.SpanishSupport}
          </a>
        </SubHeaderText> */}
      </StyledColumns>
      <HeaderText>Send us a message</HeaderText>
      <StyledForm onSubmit={e => handleSubmit(e)}>
        <div className="split">
          <InputContainer>
            <InputLabel>*First Name</InputLabel>
            <Input
              type="text"
              value={formDetails.firstName}
              onChange={e =>
                setFormDetails({
                  ...formDetails,
                  firstName: e.target.value,
                })
              }
              placeholder="Placeholder"
            />
          </InputContainer>
          <InputContainer>
            <InputLabel>*Last Name</InputLabel>
            <Input
              type="text"
              value={formDetails.lastName}
              onChange={e =>
                setFormDetails({
                  ...formDetails,
                  lastName: e.target.value,
                })
              }
              placeholder="Placeholder"
            />
          </InputContainer>
        </div>
        <InputContainer>
          <InputLabel>*Email</InputLabel>
          <Input
            type="text"
            value={formDetails.email}
            onChange={e =>
              setFormDetails({
                ...formDetails,
                email: e.target.value,
              })
            }
            placeholder="Placeholder"
          />
        </InputContainer>
        <InputContainer>
          <InputLabel>*Subject</InputLabel>
          <Input
            type="text"
            value={formDetails.subject}
            onChange={e =>
              setFormDetails({
                ...formDetails,
                subject: e.target.value,
              })
            }
            placeholder="Placeholder"
          />
        </InputContainer>
        <InputContainer>
          <InputLabel>*Message</InputLabel>
          <TextArea
            value={formDetails.text}
            onChange={e =>
              setFormDetails({
                ...formDetails,
                text: e.target.value,
              })
            }
            placeholder="Placeholder"
          />
        </InputContainer>
        {contactState === true ? (
          <LgContentText>Thank you for contacting Toot.</LgContentText>
        ) : (
          <PrimaryBtn>Send Message</PrimaryBtn>
        )}
      </StyledForm>
      <div className="animation-container" ref={animationContainer}></div>
    </Layout>
  )
}

export default ContactUs
