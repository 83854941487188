import React from "react"
import styled from "styled-components"
import { colorPrimary, colorWhite } from "../utilities/variables"
import { StyledMenuItem } from "./header"
import { PrimaryBtn } from "../elements"
import googlePlay from "../assets/images/icons/google-play-badge.png"
import appStore from "../assets/images/icons/apple-store-badge.png"
import { navigate } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"

const StyledMenu = styled.nav`
  display: grid;
  justify-content: center;
  background: ${colorWhite};
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(-100%)")};
  height: calc(100vh - 80px);
  text-align: left;
  padding: 32px;
  position: absolute;
  top: 90px;
  right: 0;
  transition: transform 0.3s ease-in-out;
  width: 100%;
  text-align: center;

  @media (max-width: 576px) {
    width: 100%;
  }

  a {
    font-size: 25px;
    font-weight: bold;
    align-self: center;
  }
  .get-started {
    width: 100% !important;
    height: 45px;
    justify-self: center;
    align-self: center;
    max-width: 300px;
    @media (min-width: 350px) {
      max-width: 320px;
    }
  }
  .download-app {
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-width: 300px;
    width: 320px;
    gap: 15px;
    img {
      max-width: 200px;
      width: 100%;
    }
    @media (min-width: 350px) {
      max-width: 320px;
    }
  }
`

const handleClick = url => {
  navigate(url)
}

export const Menu = ({ open, items, button }) => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiFooter {
        edges {
          node {
            id
            AppStoreLink
            GooglePlayLink
          }
        }
      }
    }
  `)

  const links = data.allStrapiFooter.edges[0].node

  return (
    <StyledMenu open={open}>
      {items.map(item => {
        return (
          <StyledMenuItem key={item.id} to={item.Link}>
            {item.Title}
          </StyledMenuItem>
        )
      })}
      <PrimaryBtn
        className="get-started"
        onClick={() => handleClick(button.Link)}
      >
        {button.Content}
      </PrimaryBtn>
      <div className="download-app">
        <a href={links.AppStoreLink} target="_blank" rel="noreferrer">
          <img src={appStore} alt="App Store" className="app-store" />
        </a>
        <a href={links.GooglePlayLink} target="_blank" rel="noreferrer">
          <img src={googlePlay} alt="Google Play" />
        </a>
      </div>
    </StyledMenu>
  )
}

const StyledBurger = styled.button`
  position: absolute;
  top: 25px;
  right: 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;

  &:focus {
    outline: none;
  }

  div {
    width: 32px;
    height: 3px;
    background: ${({ open }) => (open ? "#0D0C1D" : colorPrimary)};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
    }

    :nth-child(2) {
      opacity: ${({ open }) => (open ? "0" : "1")};
      transform: ${({ open }) => (open ? "translateX(20px)" : "translateX(0)")};
    }

    :nth-child(3) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`

export const Burger = ({ open, setOpen }) => {
  return (
    <StyledBurger open={open} onClick={() => setOpen(!open)}>
      <div />
      <div />
      <div />
    </StyledBurger>
  )
}
