import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { colorWhite, colorPrimary } from "../utilities/variables"
import { PrimaryBtn } from "../elements"

const StyledAlert = styled.div`
  position: fixed;
  display: ${props => (props.accept ? `none` : `grid`)};
  grid-template-columns: 1fr;
  left: 0;
  right: 0;
  top: 0;
  padding: 30px 20px;
  margin: 0;
  color: ${colorWhite};
  background-color: ${colorPrimary};
  text-align: center;
  z-index: 4;
  @media (min-width: 900px) {
    grid-template-columns: 2fr 1fr;
    padding: 30px 100px;
  }
  > * {
    margin: 0.5em;
  }
  .btn-container {
    text-align: end;
    align-self: center;
    display: grid;
    justify-items: end;
  }
  button {
    box-shadow: none;
    max-width: 100%;
    @media (min-width: 900px) {
      max-width: 200px;
    }
  }
`

export const CookiesAlert = () => {
  const [accept, setAccept] = useState(false)

  useEffect(() => {
    if (accept) {
      document.cookie = "toot=cookies_accepted"
    }
  }, [accept])

  return (
    <StyledAlert accept={accept}>
      <span>
        To help personalize content, tailor and measure ads, and provide a safer
        experience, we use cookies. By clicking or navigating the site, you
        agree to allow our collection of information on and off Instagram
        through cookies. Learn more, including about available controls: Cookies
        Policy.
      </span>
      <div className="btn-container">
        <PrimaryBtn darkBg onClick={() => setAccept(true)}>
          Accept Cookies
        </PrimaryBtn>
      </div>
    </StyledAlert>
  )
}
