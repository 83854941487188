import React, { useState, useEffect } from "react"
import styled from "styled-components"

import { StyledHeader } from "./header"
import { GlobalStyles } from "../utilities/global"
import getCookie from "../utilities/getCookie"
import { CookiesAlert } from "../components/cookies"
import { StyledFooter } from "./footer"
import { colorGrey } from "../utilities/variables"

const LayoutWrapper = styled.div`
  background-color: ${colorGrey};
  main {
    margin: ${props => (props.breadcrumb ? `0 0 70px 0` : `70px 0`)};
  }
`

const Layout = ({ children, breadcrumb = false }) => {
  const [cookies, setCookies] = useState(true)

  useEffect(() => {
    const cookie = getCookie("toot")
    if (cookie === "") {
      setCookies(false)
    }
  }, [])

  return (
    <LayoutWrapper breadcrumb={breadcrumb}>
      <GlobalStyles />
      {!cookies && <CookiesAlert />}
      <StyledHeader />
      <main>{children}</main>
      <StyledFooter />
    </LayoutWrapper>
  )
}

export default Layout
